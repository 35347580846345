<template>
  <div class="subPages">
    <p class="title">
      <img class="titleImg" src="/img/2021/navIcon4.png" alt="">
      <span class="text">参赛类别</span>
    </p>
    <template v-if="getType=='a'">
      <p class="subTitle colorGreen">主题赛A</p>
      <p class="thirdTitle">绿色人居类</p>
      <p>1、绿色建筑：建筑的节能改造、可再生能源建筑应用、低能耗建筑设计；合同能源管理、合同节水管理系统、温控系统、新风系统等的绿色化提升等进行设计。
      </p>
      <p>
2、绿色室内设计：建立在对地球生态与人类生存环境高度关怀的认识的基础上，有利于减轻地球负载，有利于人类生活环境更加健康和纯净地发展的设计。室内环境无污染、无公害，是消费者健康的绿色精神家园。
</p>
      <p>
3、城市更新：包含片区更新、微空间更新、老旧小区房改造、智慧住区等。以现代化的手法与技术，重新激发空间潜力，重塑城市风貌。
</p>
      <p>
4、乡村人居环境更新：打造乡村底色，激活乡村空间，以设计的力量创造更为合理、优质的空间形态与生态环境。核心要素包括乡村自然生态环境、生活类基础设施建设和运行维护、乡村特色文化传承等。对生态、乡土文化和可持续性的考虑；对场地生态、文化价值的考虑；关爱自然和环境，大胆采用生态设计和生态技术手段。</p>
        <p>依据大赛主题“数字创意时代，绿色设计未来”进行设计，具体遵循的原则如下:</p>
        <p>(1)坚持可持续发展及绿色生态优先的原则，要突出作品的绿色生态效应，实施绿色设计、生态设计，为人与环境和谐发展提供可能;</p>
        <p>(2)坚持以人为本的原则，关注社会、环境热点问题，并提出切实可行的解决方案;</p>
        <p>(3)坚持共生性原则，设计具有前瞻性，设计具有能够引领末来生活方式、生活理念，并具有较高的实际应用价值，可直接投放市场;</p>
        <p>(4)实现艺术与科学的共融，新技术、新理念的产品化合理应用;</p>
        <p>(5)设计要素完整，设计过程呈现具有逻辑性，设计效果精良。</p>


    </template>
      <template v-if="getType=='b'">
          <p class="subTitle colorGreen">主题赛B</p>


          <p class="thirdTitle">绿色产品类</p>
          <p>1、日常生活产品：人类日常生产、生活所涉及到的产品，基于产品全生命周期考量的二次利用、循环回收、节能减排、系统化创新等方面设计。设计应符合可持续发展的主旋律，结合当下社会经济发展和消费结构升级要求，具有前瞻性或市场价值。设计作品不仅要实现产品的功能价值、使用价值、经济价值、审美价值，更要注重实现产品的社会价值、生态环境价值促进人与自然和谐发展。</p>
          <p>
              2、特殊群体绿色生活方式：全球化视⻆下，从社会⺠生问题出发，以尊重、独立、安全绿色为前提，使用“绿色设计”方法，为儿童、妇女、孕妇、残障人士、老年人等群体而设计；或为战争地区、贫困地区的弱势人群而设计。包括但不仅限于服装与服饰、净水设备、装备设计、文化创意、公共服务产品/系统等。</p>
          <p>
              3、绿色交通：以现代科学技术为基础，为推动形成绿色、环保、可持续的城乡交通生态，而进行的创新设计或规划，包括但不仅限于：交通工具设计、交通基础设施绿色化改造、城市路网优化配置方案、城市交通系统改进与周边产品设计、城乡交通智慧化管理、基于绿色节能的共享领域服务设计与系统优化等。</p>
          <p>4、绿色能源:对⻛能、太阳能、海洋能、地热能、生物质能、氢能等可再生能源的开发利用与创新应用；对传统能源的清洁、高效利用；能源存储与管理方式优化和创新等。</p>
          <p>5、绿色服装服饰设计:包括服装、配饰(箱、包、鞋、丝巾、帽子)、非遗、陶瓷、传统手工艺保护与创新、游戏手办、益智玩具等。</p>
          <p>依据大赛主题“数字创意时代，绿色设计未来”进行设计，具体遵循的原则如下:</p>
          <p>(1)坚持可持续发展及绿色生态优先的原则，要突出作品的绿色生态效应，实施绿色设计、生态设计，为人与环境和谐发展提供可能;</p>
          <p>(2)坚持以人为本的原则，关注社会、环境热点问题，并提出切实可行的解决方案;</p>
          <p>(3)坚持共生性原则，设计具有前瞻性，设计具有能够引领末来生活方式、生活理念，并具有较高的实际应用价值，可直接投放市场;</p>
          <p>(4)实现艺术与科学的共融，新技术、新理念的产品化合理应用;</p>
          <p>(5)设计要素完整，设计过程呈现具有逻辑性，设计效果精良。</p>

      </template>
      <template v-if="getType=='c'">
          <p class="subTitle colorGreen">主题赛C</p>

          <p class="thirdTitle">绿色视觉传达设计类</p>
          <p>1、平面海报：围绕17个可持续发展目标，通过绿色设计理念，开展相关创意设计。</p>
          <p>2、绿色包装：围绕电子产品、陶瓷、药品、⻝品、日化用品和烟酒茶叶等与人们生活密切相关的产品包装展开创新设计，主题突显“数字创意时代，绿色设计未来”，倡导绿色、环保、安全的包装设计创意理念，依据3R1D原则（减量化、可重复、可循环、可降解、无毒害）进行设计，力求包装的造型、结构、材料、色彩无害化、包装废弃物便于回收和可再生利用，融实用性、经济性、时尚性于一体。</p>
          <p>3、乡村振兴品牌设计：通过创意设计赋能乡村振兴，讲好中国文化产业赋能乡村振兴故事，选优评尖带动乡村产业升级与经济效益。</p>
          <p>4、书籍设计：让更多读者了解书籍设计艺术，走进阅读，品味到书籍之美、阅读之美。</p>
          <p>5、新媒体设计：结合大赛主题“数字创意时代，绿色设计未来”，以动画影片、微电影、多媒体设计、动态海报、交互设计、交互装置、交互绘本等形式进行设计创作。</p>
          <p>依据大赛主题“数字创意时代，绿色设计未来”进行设计，具体遵循的原则如下:</p>
          <p>(1)坚持可持续发展及绿色生态优先的原则，要突出作品的绿色生态效应，实施绿色设计、生态设计，为人与环境和谐发展提供可能;</p>
          <p>(2)坚持以人为本的原则，关注社会、环境热点问题，并提出切实可行的解决方案;</p>
          <p>(3)坚持共生性原则，设计具有前瞻性，设计具有能够引领末来生活方式、生活理念，并具有较高的实际应用价值，可直接投放市场;</p>
          <p>(4)实现艺术与科学的共融，新技术、新理念的产品化合理应用;</p>
          <p>(5)设计要素完整，设计过程呈现具有逻辑性，设计效果精良。</p>
      </template>
    <template v-if="getType=='d'">
      <p class="subTitle colorGreen">命题赛D</p>
      <p class="thirdTitle">命题赛：校园绿色空间设计<span class="size12"></span></p>
      <p>2020年教育部办公厅、国家发展改革委办公厅联合印发《绿色学校创建行动方案》通知，要求以大中小学为绿色校园创建对象，建设绿色环保校园。</p>
      <p>为贯彻落实《行动方案》通知内容，推动“校园绿色空间项目”落地实施，特举办此命题赛事。</p>
      <!-- <p class="thirdTitle">设计主题</p>
      <p>绿色设计助力碳中和</p> -->
      <p class="thirdTitle">设计目标</p>
      <p>参赛者以“木塑”材料为主，自己设计自己的“家”，其中包含校园绿色家居/公共场景设计（包括但不限于宿舍、教室、礼堂、食堂、办公室、图书室、运动场）设计。</p>
      <p class="thirdTitle">设计要求</p>
      <p class="hang"><span class="num">1</span>校园公共空间场景设计（包括但不限于宿舍、教室、礼堂、食堂、办公室、图书室、运动场）。设计者通过对既有的空间、场地、人居、视觉、包装设计等方面的创意设计，提升学生学习生活的舒适性及健康性开展设计。</p>
      <p class="hang"><span class="num">2</span>所设计的空间不少于一处体现木塑复合材料（见附件6-《材料说明》）的应用。（大赛组委会将安排3次“木塑材料”线上公益课，需参赛者注意主办方公众号【北京绿色设计促进会】动态）。</p>
      <p class="thirdTitle">参赛要求</p>
      <p>采取个人或小组形式参赛，每组成员不超过3人，指导教师不超过2名，同一名指导教师可指导多位选手。</p>


    </template>
      <template v-if="getType=='e'">
          <p class="subTitle colorGreen">命题赛E</p>


          <p class="thirdTitle">命题赛：邢台平乡童车、儿童电动玩具绿色创新设计</p>
          <p>邢台平乡县已经形成了每年达上百亿产值的自行⻋和童⻋产业带，市场每两辆童⻋中就有一辆产自平乡，邢台平乡被誉为“中国童⻋之都”。为了寻求更大的发展空间，各童⻋企业不断自主创新，大力推动绿色出行，绿色设计理念在平乡儿童用品器材领域得以广泛应用。</p>
          <p>为了进一步倡导和推动绿色设计理念的落地，面向全球大学生征集优秀设计作品，为邢台平乡县童⻋企业提供更多绿色、环保、安全、舒适的童⻋设计方案，为推动绿色设计发展和儿童用品器材行业的可持续发展贡献力量。</p>
          <!-- <p class="thirdTitle">设计主题</p>
          <p>南网知行，匠心读阅</p> -->
          <p class="thirdTitle">赛事目标</p>
          <p>鼓励大学生参与绿色设计，激发创造力和创新能力，提供可持续解决方案，以满足儿童用品器材市场的需求。通过本次设计大赛，望能够挖掘和表彰那些具有创新意识和绿色设计理念的大学生设计师，进一步推乡童⻋企业的创新能力和市场竞争力，促进绿色骑行文化的发展，为社会提供更多绿色、环保、安全、舒适的儿童行业产品。</p>
          <p class="thirdTitle">设计要求</p>
          <p class="hang"><span class="num">1</span>安全性设计要求：设计作品应注重儿童用品器材的安全性，包括稳定性、结构强度、防倾翻设计、避震系统、防护装置等方面。设计者需要考虑如何最大程度地保障儿童的安全，以避免潜在的伤害和事故发生。</p>
          <!-- <p class="hangBig size12">（1）需实现的功能：阅读、网络云课堂、交流分享、益智活动、特色文化展示。</p> -->
          <!-- <p class="hangBig size12">（2）结合主办方所提供基础VI元素和要求（见附件）进行书屋设计。</p> -->
          <p class="hang"><span class="num">2</span>可持续性设计要求：设计作品应符合环境可持续发展的要求，包括选用环保材料、降低资源消耗、减少二氧化碳排放、设计易于回收和再利用的部件等方面。设计者需要考虑如何将环保和可持续发展的理念融入产品设计中，以减少对环境的负面影响。</p>
          <!-- <p class="hangBig">砖混结构多层建筑，室内面积：约70-120㎡，高度：约4m，开间：约3.5-4m,进深：约5-6m。</p> -->
          <p class="hang"><span class="num">3</span>舒适性设计要求：设计作品应注重儿童用品器材的舒适性，包括人体工学设计、⻋架结构的合理设计、座椅和把手的舒适性、调节性能等方面。设计者需要考虑如何提高儿童的应用体验，使其更加舒适和愉快。</p>
          <p class="hang"><span class="num">4</span>美观性设计要求：设计作品应注重儿童用品器材的美观性，并兼顾儿童的审美需求。产品的外观设计应简洁、流畅、富有创意，色彩搭配要活泼、吸引人，并能吸引儿童的注意力。设计者需要通过元素如形状、贴花、图案等，为儿童骑行器材赋予独特的视觉魅力，增加产品的亲和力和吸引力。</p>
          <p class="hang"><span class="num">5</span>实用性设计要求：设计作品应注重儿童用品器材的实用性，方便家⻓或监护人的使用和维护。产品应具备易于组装和调整的特点，并考虑不同年龄和身高阶段的特点，提供相应的可调节性和适应性。设计者还需要考虑便利性、储存性和细节的考虑，以提升产品的实用性和用户的便利性。</p>

          <!-- <p class="hangBig">每间书屋的设计、建设及硬件配备费用为25万，参赛者在进行书屋设计时兼顾预算要求。</p> -->
          <!-- <p class="hangBig">说明：书屋必须包含3项硬件—5000册图书；满足至少45名小学生同时阅读的空间和设备，形式不限；电子智能互动屏1个。</p> -->
          <p class="thirdTitle">参赛要求</p>
          <p>采取个人或小组形式参赛，每组成员不超过3人，指导教师不超过2名，同一名指导教师可指导多位选手。</p>




      </template>
      <template v-if="getType=='f'">
          <p class="subTitle colorGreen">命题赛F</p>
           <p class="thirdTitle">命题赛：儿童友好型绿色设计产品</p>
          <p>儿童友好城市的建设，承载着人⺠对美好生活的向往，也担负着对儿童末来发展的责任与使命。</p>
          <p>从“人⺠至上”视⻆理解儿童工作，儿童友好体现的是把儿童的需求放在更为重要的位置;从“社会治理”视⻆寻求可持续发展，儿童友好是一种战略而系统的善治观；从“城市发展〞视角践行高质量一体化，儿童友好城市的创建过程就是在发挥赋能城市的综合示范作用；从“产业经济”视角谋划产业升级，建设儿童友好城市有助于打造儿童产业生态链;从“中国特色”视角探索城市模式，需要因地制宜建设符合中国国情的儿童友好城市。</p>
          <!-- <p class="thirdTitle">设计主题</p>
          <p>南网知行，匠心读阅</p> -->
          <p class="thirdTitle">赛事目标</p>
          <p>儿童各阶段用品有婴儿⻋、儿童电动⻋、自行⻋、学步⻋、沐浴露和洗发水、保温杯、奶瓶、热奶器、尿布、湿巾、护臀膏、隔尿垫、婴儿油、衣服、奶瓶、奶粉、磨牙器、护肤品、床上用品、洗涤用品、洗浴用品、玩具、餐具、消毒用品、手推⻋、穿衣、小孩用的坐便器等。</p>
          <p>具体可分为以下几个方面：</p>
          <p>纺织服鞋：可围绕儿童四季的服装和鞋帽款式进行创意设计；</p>
          <p>交通工具：可围绕婴儿⻋、汽⻋上的婴儿座、汽⻋上的儿童应急措施等进行设计；</p>
          <p>体育用品：可围绕滑板、自行⻋等进行设计；</p>
          <p>家居家具:可围绕儿童使用的毛巾、四件套、枕头等家居用品，家具以儿童桌椅、儿童餐具等用品进行设计；</p>
          <p>文具玩具：可围绕儿童用的纸、笔、写字板、橡皮擦等文具用品，玩具含益智类玩具、棋牌类玩具、模型类玩具、学习类玩具等进行设计；</p>
          <p>电子产品：可围绕儿童手表、儿童阅读机、儿童相机等进行设计；</p>
          <p>儿童文创：儿童绘本、根据传统文化为基础设计出实用、合理的文创产品。</p>
          <p class="thirdTitle">设计要求</p>
          <p class="hang"><span class="num">1</span>可利用AI技术辅助产品设计。</p>
          <!-- <p class="hangBig size12">（1）需实现的功能：阅读、网络云课堂、交流分享、益智活动、特色文化展示。</p> -->
          <!-- <p class="hangBig size12">（2）结合主办方所提供基础VI元素和要求（见附件）进行书屋设计。</p> -->
          <p class="hang"><span class="num">2</span>设计产品需基于全生命周期理念，对组织层面和产品层面，覆盖资源、能源、环境、品质的基本要求和由产品本体、生产工艺、运输包装、维护维修、回收拆解、再利用生产产品组成的产品要求。</p>

          <!-- <p class="hangBig">每间书屋的设计、建设及硬件配备费用为25万，参赛者在进行书屋设计时兼顾预算要求。</p> -->
          <!-- <p class="hangBig">说明：书屋必须包含3项硬件—5000册图书；满足至少45名小学生同时阅读的空间和设备，形式不限；电子智能互动屏1个。</p> -->
          <p class="thirdTitle">参赛要求</p>
          <p>采取个人或小组形式参赛，每组成员不超过3人，指导教师不超过2名，同一名指导教师可指导多位选手。</p>




      </template>

  </div>
</template>

<script>
export default {
  computed:{
    getType(){
      let id = this.$route.query.id
      if(id){
        return id
      }
      return 'a'
    }
  },
  // mounted(){
  //   let scrollTop = 0, id = this.$route.query.id
  //   if(id){
  //     scrollTop = document.getElementById(id).offsetTop - 60
  //     document.body.scrollTop = scrollTop
  //     document.documentElement.scrollTop = scrollTop
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.num{
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background: #00913A;
  border-radius: 20px;
  margin-right: 3px;
  padding-left: 15px;
}
</style>